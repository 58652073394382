import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo, TitleUnderlineThree } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.png"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.png"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.png"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageFourMobile from "./images/imagesMobile/imageThree.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Unifica la comunicación de ventas corporativas",
      description: "Actualmente, Coolboox utiliza 8 canales diferentes para gestionar sus ventas corporativas, lo que genera confusión entre los clientes. Con la API de WhatsApp de Beex, puedes centralizar la atención en un único número, simplificando la experiencia del cliente.<br /><br />Además, Beex permite enrutar automáticamente las consultas a los agentes más adecuados según la ubicación, tipo de cliente o producto, optimizando los tiempos de respuesta y mejorando la organización.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Mejora la comunicación postventa",
      description: "La experiencia del cliente no debe terminar con la compra. Actualmente, los clientes de Coolbox solo reciben un correo electrónico con la factura, sin un seguimiento durante el proceso de entrega.<br /><br />Beex soluciona esto integrando canales como WhatsApp y correo electrónico para enviar notificaciones automáticas en cada etapa de la entrega. Esto genera confianza, reduce consultas innecesarias y mejora la satisfacción postventa.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Recupera carritos abandonados",
      description: "Los carritos abandonados representan una oportunidad perdida si no se toman medidas. Actualmente, Coolbox no envía recordatorios a los clientes que dejan artículos sin comprar, incluso cuando están registrados en la plataforma.<br /><br />Con Beex, puedes configurar flujos automáticos que envíen mensajes personalizados por WhatsApp o correo electrónico, motivando a los clientes a completar sus compras y aumentando las tasas de conversión.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
  ]

  return (
    <section className="container-experience-pandero">
      <p className="container-experience-pandero-title">
        Utiliza Beex para crear experiencias únicas que <TitleUnderlineOne underline="aumenten el" /> <TitleUnderlineTwo underline="ticket de" /> <TitleUnderlineThree underline="venta" /> y la  <TitleUnderlineOne underline="fidelización" /> <TitleUnderlineTwo underline="de clientes" /> <TitleUnderlineTwo underline="en Coolbox" />
      </p>


      <div className="container-experience-pandero-cardsOne">
        <div>
          <section className="container-experience-pandero-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 6px 32px" }}>
            <section style={{ padding: "0px 26px" }}>
              <p className="container-experience-pandero-cardsOne-card-title">Unifica la comunicación de ventas corporativas </p>
              <p className="container-experience-pandero-cardsOne-card-description">
                Actualmente, Coolboox utiliza 8 canales diferentes para gestionar sus ventas corporativas, lo que genera confusión entre los clientes. Con la API de WhatsApp de Beex, puedes centralizar la atención en un único número, simplificando la experiencia del cliente.
                <br /><br />
                Además, Beex permite enrutar automáticamente las consultas a los agentes más adecuados según la ubicación, tipo de cliente o producto, optimizando los tiempos de respuesta y mejorando la organización.
              </p>
            </section>
            <section>
              <img
                className="container-experience-pandero-cardsOne-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        <div className="container-experience-pandero-cardsTwo">
          <div className="container-experience-pandero-cardsOne-column">
            <section className="container-experience-pandero-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
              <section>
                <p className="container-experience-pandero-cardsOne-card-title">Mejora la comunicación postventa</p>
                <p className="container-experience-pandero-cardsOne-card-description">
                  La experiencia del cliente no debe terminar con la compra. Actualmente, los clientes de Coolbox solo reciben un correo electrónico con la factura, sin un seguimiento durante el proceso de entrega.
                  <br /><br />
                  Beex soluciona esto integrando canales como WhatsApp y correo electrónico para enviar notificaciones automáticas en cada etapa de la entrega. Esto genera confianza, reduce consultas innecesarias y mejora la satisfacción postventa.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsOne-card-image"
                  src={imageTwoDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
          <div className="container-experience-pandero-cardsTwo-cards-column">
            <section className="container-experience-pandero-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 6px" }}>
              <section>
                <p className="container-experience-pandero-cardsTwo-card-title">Recupera carritos abandonados</p>
                <p className="container-experience-pandero-cardsTwo-card-description">
                Los carritos abandonados representan una oportunidad perdida si no se toman medidas. Actualmente, Coolbox no envía recordatorios a los clientes que dejan artículos sin comprar, incluso cuando están registrados en la plataforma.
<br /><br />
Con Beex, puedes configurar flujos automáticos que envíen mensajes personalizados por WhatsApp o correo electrónico, motivando a los clientes a completar sus compras y aumentando las tasas de conversión.
                 </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsTwo-card-image"
                  src={imageThreeDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
        </div>
      </div>


      <div className="container-experience-pandero-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-pandero-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-pandero-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-pandero-cards-mobile-card-description" dangerouslySetInnerHTML={{ __html: elemento.description }}>

                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-pandero-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
