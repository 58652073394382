/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqi from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Cobra from "@components/sharedComponents/customersLogos/logos/Cobra.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import ClinicaAviva from "@components/sharedComponents/customersLogos/logos/ClinicaAviva.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Cafae from "@components/sharedComponents/customersLogos/logos/Cafae.svg"
import Farenet from "@components/sharedComponents/customersLogos/logos/Farenet.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/kpi-customer-success.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/expectativas-cliente.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/interaccion-cliente.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/procesos-para-automatizar.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/estrategias-para-satisfacer-cliente.jpg"

// logos testimonios
import KontigoTes from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import IttsaBusTes from "@components/sharedComponents/testimonies/images/logos/ittsaBus.svg"
import SavarTes from "@components/sharedComponents/testimonies/images/logos/savar.svg"
import CobraTes from "@components/sharedComponents/testimonies/images/logos/cobra.svg"

//foto testimonio desktop
import kontigoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/kontigo.png"
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import ittsaBusDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/ittsaBus.png"
import savarDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/savar.png"
import cobraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cobra.png"

//foto testimonio desktop
import kontigoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/kontigo.png"
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import ittsaBusMobile from "@components/sharedComponents/testimonies/images/fotosMobile/ittsaBus.png"
import savarMobile from "@components/sharedComponents/testimonies/images/fotosMobile/savar.png"
import cobraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cobra.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageColombia/pageAtencionCliente/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageColombia/pageAtencionCliente/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageColombia/pageAtencionCliente/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageColombia/pageAtencionCliente/images/accordionLeft/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageAtencion.jpg"

import { TitleUnderlineIntro, TitleUnderlineIntroTwo } from "@components/pageColombia/pageAtencionCliente/titleUnderline/titleUnderlineAccordionRight"

import imageStartContactCenter from "@components/pageColombia/pageAtencionCliente/images/imageIntro.webp"

//image indrustias
import banca from "@components/pageCasosdeUso/components/industries/images/banca.png"
import salud from "@components/pageCasosdeUso/components/industries/images/salud.png"
import ecommerce from "@components/pageCasosdeUso/components/industries/images/ecommerce.png"
import educacion from "@components/pageCasosdeUso/components/industries/images/educacion.png"
import concesionaria from "@components/pageCasosdeUso/components/industries/images/concesionaria.png"
import contact from "@components/pageCasosdeUso/components/industries/images/contact.png"

const titleStart = (
  <p>
    Sé la empresa <TitleUnderlineIntroTwo underline="con la" /> <TitleUnderlineIntro underline="mejor CX" />  en Colombia con una plataforma de atención al cliente con IA
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Optimiza la atención al cliente con autoservicios de IVR y chatbots con IA. Unifica tus canales, crea workflows inteligentes e integra tus herramientas favoritas para brindar un servicio excepcional en los canales que prefieran tus clientes.
        <br /><br />
        Mejora tu CSAT y NPS con tecnología omnicanal que potencia la comunicación en voz, WhatsApp, correo y más, automatizando procesos de servicio, marketing y ventas.
      </p>
    ),
    image: imageStartContactCenter,
    textbutton: "Solicitar demo",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqi,
    Auna,
    Alfin,
    Prestamype,
    Cobra,
    Civa,
    Synlab,
    ClinicaAviva,
    Conexo,
    Cafae,
    Farenet
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqi, Auna, Alfin, Prestamype, Cobra],
    arrayTwo: [Civa, Synlab, ClinicaAviva, Conexo, Cafae, Farenet],
  },
  articles: [
    {
      image: article1,
      title: "10 KPIs de customer success que debes conocer",
      textbody:
        "Conoce 10 KPIs de customer success y cómo se calcula cada uno con esta guía completa.",
      link: "https://beexcc.com/blog/kpi-customer-success/",
      card: true,
    },
    {
      image: article2,
      title: "¿Cómo mejorar las expectativas de un cliente? 7 consejos",
      textbody:
        "Descubre las mejores prácticas para mejorar las expectativas de un cliente con esta guía completa.",
      link: "https://beexcc.com/blog/expectativas-cliente/",
      card: true,
    },
    {
      image: article3,
      title: "Interacción con el cliente: 8 tips infalibles para mejorarla",
      textbody:
        "¿Cómo mejorar la interacción con el cliente? Descubre 8 estrategias para construir relaciones fuertes.",
      link: "https://beexcc.com/blog/interaccion-cliente/",
    },
    {
      image: article4,
      title: "Cuáles son los procesos para automatizar en la atención al cliente",
      textbody:
        "Te ayudamos a conocer los procesos que se pueden automatizar durante la atención al cliente de una empresa.",
      link: "https://beexcc.com/blog/procesos-para-automatizar/",
    },
    {
      image: article5,
      title: "Conoce 10 estrategias para satisfacer al cliente y aumenta tu tasa de retención",
      textbody:
        "Explora 10 estrategias para satisfacer al cliente, desde la escucha activa hasta el seguimiento post venta. ¡ENTRA AQUÍ y construye relaciones duraderas!",
      link: "https://beexcc.com/blog/estrategias-para-satisfacer-cliente/",
    },
  ],
  contacts: {
    title: "Potencia tus operaciones de Contact Center con tecnología cloud",
    textbody: "Cuéntanos sobre tus operaciones outbound o inbound para que nuestros asesores te brinden un feedback que te permita mejorar tus servicios.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: KontigoTes,
      title:
        "Con WhatsApp API, mejoramos nuestro tiempo de primera respuesta en <1 minuto",
      textbody:
        "Kontigo, fintech respaldada por Mastercard, es capaz de cerrar el 98% de sus casos en un primer contacto gracias a sus chatbots integrados a la API de WhatsApp Business.",
      name: "Analucía Siguas Velásquez",
      post: "Gerente de Marketing y Producto en GMoney",
      link: "/casos-de-exito/kontigo/",
      imgDesktop: kontigoDesktop,
      imgMobile: kontigoMobile,
      metrics: [
        {
          metric: "98%",
          descripcion: "casos cerrados en primer contacto",
          color: "#FFB800",
        },
        {
          metric: "<1 minuto",
          descripcion: "tiempo de primera respuesta",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#FFB800",
        },
      ],
    },
    {
      image: SavarTes,
      title:
        "Con Beex, mejoramos +20% la satisfacción de nuestros clientes en entregas de pedidos",
      textbody:
        "Savar, empresa líder en logística, asegura que sus clientes reciban actualizaciones constantes y oportunas por WhatsApp, logrando mejorar sus índices de satisfacción.",
      name: "Dinkar Rios Macedo",
      post: "Jefe de Sistemas de Savar",
      link: "/casos-de-exito/savar/",
      imgDesktop: savarDesktop,
      imgMobile: savarMobile,
      metrics: [
        {
          metric: "+20%",
          descripcion: "aumentó la satisfacción de sus clientes",
          color: "#FFB800",
        },
        {
          metric: "+30%",
          descripcion: "se redujeron las consultas repetitivas",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#FFB800",
        },
      ],
    },
    {
      image: IttsaBusTes,
      title:
        "Con Beex, mejoramos en 22% la satisfacción de nuestros usuarios al reagendar sus viajes",
      textbody:
        "IttsaBus, empresa de transporte terrestre, automatiza sus procesos de atención al cliente y marketing con Beex, mejorando sus métricas más importantes de satisfacción.",
      name: "Iris Pérez Medina",
      post: "Coordinadora de Marketing y Servicio al Cliente",
      link: "/casos-de-exito/ittsa-bus/",
      imgDesktop: ittsaBusDesktop,
      imgMobile: ittsaBusMobile,
      metrics: [
        {
          metric: "95%",
          descripcion: "mejoraron su tiempo de primera respuesta",
          color: "#FFB800",
        },
        {
          metric: "22%",
          descripcion: "mejoraron satisfacción en reprogramación de viajes",
          color: "#5831D8",
        },
      ],
    },
    {
      image: AvivaTes,
      title:
        "Con los workflows de WhatsApp mejoramos en +30% el tiempo de agenda de citas",
      textbody:
        "Aviva, parte del grupo Intercorp, clínica multiespecialidad con diferentes servicios en salud, ha logrado automatizar su proceso de citas médicas con chatbots en WhatsApp.",
      name: "Luis Ordinola",
      post: "Coordinador de mejora de procesos",
      link: "/casos-de-exito/clinica-aviva/",
      imgDesktop: avivaDesktop,
      imgMobile: avivaMobile,
      metrics: [
        {
          metric: "+30%",
          descripcion: "mejoró el tiempo de agenda de citas",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "ahora representa WhatsApp como canal para agendar citas",
          color: "#5831D8",
        },
      ],
    },
    {
      image: CobraTes,
      title:
        "Con Beex, mejoramos en +20% el contacto con clientes para agendar instalaciones",
      textbody:
        "Grupo Cobra, empresa líder en telecomunicaciones, ha mejorado el contacto con sus clientes potenciales por medio de WhatsApp, en comparación con su canal telefónico.",
      name: "Luis Pasco Ramírez",
      post: "Jefe de Operaciones en Grupo Cobra",
      link: "/casos-de-exito/grupo-cobra/",
      imgDesktop: cobraDesktop,
      imgMobile: cobraMobile,
      metrics: [
        {
          metric: "+20%",
          descripcion: "mejoró su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+45%",
          descripcion: "ahan reducido sus tiempos de respuesta",
          color: "#5831D8",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  accordionRight: [
    {
      title: "Autoservicio con agentes de chat y voz",
      description: "<p>Empodera a tus clientes para resolver consultas y realizar transacciones de forma autónoma con chatbots de IA e IVR. Desde seguimiento de pedidos hasta pagos, integra estas herramientas con tu CRM o ERP para ofrecer una experiencia rápida y personalizada.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Integraciones fluidas",
      description: "<p>Conecta plataformas como Salesforce, HubSpot o Zoho con tus canales de contacto y ofrece una atención unificada. Optimiza cada interacción y utiliza análisis de datos para adaptar tus servicios a las necesidades de tus clientes.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "IA y workflows automatizados",
      description: "<p>Automatiza tus procesos con flujos inteligentes potenciados por IA para marketing, ventas o atención al cliente. Gestiona campañas y chatbots en canales como WhatsApp, correo o SMS, maximizando eficiencia y resultados.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Supervisión en tiempo real",
      description: "<p>Monitorea la actividad de tus agentes en tiempo real para mejorar la gestión y productividad. Audita sus tareas y refuerza su desempeño con feedback oportuno que impulse la calidad del servicio.</p>",
      image: ImageAccordionLeftFour
    },
  ],
  bannerVideo: {
    title: "¿Cómo alcanzar la automatización de la atención al cliente?",
    description: "<p>La automatización al cliente ayuda a las empresas en muchos aspectos. Por ejemplo, mejora la productividad de los agentes, proporciona una experiencia más rápida y eficiente para los usuarios. <br /><br /> Nuestra Key Account Manager, Katherine Cantelmi, nos cuenta detalladamente el proceso para alcanzar la automatización en la atención al cliente.</p>",
    image: imageVideo
  },
  industries: [
    {
      tab: "01. E-commerce",
      title: "Plataforma de atención al cliente para E-commerce y Retail",
      text: (
        <p>
          <br />
          Facilita la experiencia del cliente mediante un autoservicio para la gestión de pedidos, seguimiento de envíos y resolución de consultas frecuentes. Optimiza la interacción digital con los usuarios, mejorando la satisfacción y fidelización del cliente.
          <br /> <br /> <br />
        </p>
      ),
      image: ecommerce,
      active: "container-industries-five-navbar-menu-button-active",
      link: "/hablemos/",
      textLink: "Mejora tu CX en E-commerce",
    },
    {
      tab: "02. Banca y finanzas",
      title: "Plataforma de atención al cliente para Banca, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Ofrece un autoservicio integral para la gestión de cuentas, transferencias y consultas financieras. Mejora la accesibilidad y seguridad en las transacciones digitales, fortaleciendo la confianza del cliente con tu banco, seguro o fintech.
          <br /> <br /> <br />
        </p>
      ),
      image: banca,
      link: "/hablemos/",
      textLink: "Mejora tu CX en Banca y Finanzas",
    },
    {
      tab: "03. Salud",
      title: "Plataforma de atención al cliente para Clínicas, Hospitales y Centros de Salud",
      text: (
        <p>
          <br />
          Implementa un autoservicio para la gestión de citas, consultas frecuentes y disponibilidad de médicos en tu clínica u hospital. Mejora la atención digital con el paciente y aumenta la credibilidad de tu institución al ofrecer una experiencia más conveniente y eficiente para los usuarios.
          <br /> <br /><br />
        </p>
      ),
      image: salud,
      link: "/hablemos/",
      textLink: "Mejora tu CX en Salud",
    },
    {
      tab: "04. Educación",
      title: "Plataforma de atención al cliente para Universidades, Institutos y Colegios",
      text: (
        <p>
          <br />
          Proporciona una experiencia omnicanal para la gestión de matrículas, consultas académicas y seguimiento del progreso estudiantil. Incrementa la eficiencia administrativa y la comunicación entre instituciones educativas, profesores y alumnos.
          <br /> <br /><br />
        </p>
      ),
      image: educacion,
      link: "/hablemos/",
      textLink: "Mejora tu CX en Eduación",
    },
    {
      tab: "05. Concesionarias",
      title: "Plataforma de atención al cliente para Concesionarias",
      text: (
        <p>
          <br />
          Automatiza la gestión de citas de prueba de vehículos, consultas sobre modelos y disponibilidad de stock. Optimiza la experiencia del cliente en línea, generando un mayor interés y confianza en la marca automotriz.
          <br /> <br /><br />
        </p>
      ),
      image: concesionaria,
      link: "/hablemos/",
      textLink: "Mejora tu CX en Inmobiliarias",
    },
    {
      tab: "06. Contact Center",
      title: "Plataforma de atención al cliente para BPOs y Contact Center",
      text: (
        <p>
          <br />
          Despliega un sistema integrado para la gestión de consultas, seguimiento de casos y soporte técnico. Mejora la eficiencia operativa y la satisfacción del cliente al proporcionar respuestas rápidas y precisas a través de múltiples canales de comunicación.
          <br /> <br /><br />
        </p>
      ),
      image: contact,
      link: "/hablemos/",
      textLink: "Mejora tu CX en Contact Center",
    },
  ],
}

export default data
