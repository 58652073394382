/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import Select from "react-select"
import imageGlobo from "./globo.svg"
import { Link } from "gatsby"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"


const checked = (
    <svg
        width="26"
        height="20"
        viewBox="0 0 26 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.5068 0.67906L9.91704 15.0584L3.4229 8.59475C2.64923 7.82192 1.38323 7.82192 0.586112 8.59475C-0.187558 9.36758 -0.187558 10.6322 0.586112 11.4285L8.5807 19.4144C8.95581 19.7891 9.47159 19.9999 9.98737 19.9999C10.0108 19.9999 10.0343 19.9999 10.0577 19.9999C10.6204 19.9765 11.1362 19.7423 11.4878 19.3207L25.4842 3.32543C26.211 2.48234 26.1172 1.24112 25.2967 0.491706C24.4995 -0.234289 23.2335 -0.140612 22.5068 0.67906Z"
            fill="#33D868"
        />
    </svg>
)

const info = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 3.25C6.27208 3.25 3.25 6.27208 3.25 10C3.25 13.7279 6.27208 16.75 10 16.75C13.7279 16.75 16.75 13.7279 16.75 10C16.75 6.27208 13.7279 3.25 10 3.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10ZM10 8.41667C10.4142 8.41667 10.75 8.75245 10.75 9.16667V13.3333C10.75 13.7475 10.4142 14.0833 10 14.0833C9.58579 14.0833 9.25 13.7475 9.25 13.3333V9.16667C9.25 8.75245 9.58579 8.41667 10 8.41667ZM10 7.5C10.4602 7.5 10.8333 7.1269 10.8333 6.66667C10.8333 6.20643 10.4602 5.83333 10 5.83333C9.53976 5.83333 9.16667 6.20643 9.16667 6.66667C9.16667 7.1269 9.53976 7.5 10 7.5Z"
            fill="#60636D"
        />
    </svg>
)
const countries = [
    {
        value: "Argentina",
        label: "Argentina",
        priceOne: "0.0356",
        priceTwo: "0.0658",
        priceThree: "0.0407",
        priceFour: "0.0448",
        image: "https://flagcdn.com/w320/ar.png",
    },
    {
        value: "Brasil",
        label: "Brasil",
        priceOne: "0.0340",
        priceTwo: "0.0665",
        priceThree: "0.0355",
        priceFour: "0.0390",
        image: "https://flagcdn.com/w320/br.png",
    },
    {
        value: "Chile",
        label: "Chile",
        priceOne: "0.0494",
        priceTwo: "0.0929",
        priceThree: "0.0567",
        priceFour: "0.0626",
        image: "https://flagcdn.com/w320/cl.png",
    },
    {
        value: "Colombia",
        label: "Colombia",
        priceOne: "0.0100",
        priceTwo: "0.0165",
        priceThree: "0.0117",
        priceFour: "0.0125",
        image: "https://flagcdn.com/w320/co.png",
    },
    {
        value: "Egipto",
        label: "Egipto",
        priceOne: "0.0684",
        priceTwo: "0.1113",
        priceThree: "0.0658",
        priceFour: "0.0727",
        image: "https://flagcdn.com/w320/eg.png",
    },
    {
        value: "France",
        label: "France",
        priceOne: "0.0899",
        priceTwo: "0.1472",
        priceThree: "0.0731",
        priceFour: "0.0808",
        image: "https://flagcdn.com/w320/fr.png",
    },
    {
        value: "Germany",
        label: "Germany",
        priceOne: "0.0859",
        priceTwo: "0.1405",
        priceThree: "0.0808",
        priceFour: "0.0893",
        image: "https://flagcdn.com/w320/de.png",
    },
    {
        value: "India",
        label: "India",
        priceOne: "0",
        priceTwo: "0.0139",
        priceThree: "0",
        priceFour: "0.0082",
        image: "https://flagcdn.com/w320/in.png",
    },
    {
        value: "Indonesia",
        label: "Indonesia",
        priceOne: "0.0230",
        priceTwo: "0.0451",
        priceThree: "0.0340",
        priceFour: "0.0240",
        image: "https://flagcdn.com/w320/id.png",
    },
    {
        value: "Israel",
        label: "Israel",
        priceOne: "0.0426",
        priceTwo: "0.0731",
        priceThree: "0.0418",
        priceFour: "0.0460",
        image: "https://flagcdn.com/w320/il.png",
    },
    {
        value: "Italy",
        label: "Italy",
        priceOne: "0.0426",
        priceTwo: "0.0731",
        priceThree: "0.0418",
        priceFour: "0.0460",
        image: "https://flagcdn.com/w320/it.png",
    },
    {
        value: "Malaysia",
        label: "Malaysia",
        priceOne: "0.0260",
        priceTwo: "0.0900",
        priceThree: "0.0220",
        priceFour: "0.0240",
        image: "https://flagcdn.com/w320/my.png",
    },
    {
        value: "Mexico",
        label: "Mexico",
        priceOne: "0.0145",
        priceTwo: "0.0476",
        priceThree: "0.0279",
        priceFour: "0.0306",
        image: "https://flagcdn.com/w320/mx.png",
    },
    {
        value: "Netherlands",
        label: "Netherlands",
        priceOne: "0.0931",
        priceTwo: "0.1637",
        priceThree: "0.0760",
        priceFour: "0.0840",
        image: "https://flagcdn.com/w320/nl.png",
    },
    {
        value: "Nigeria",
        label: "Nigeria",
        priceOne: "0.0350",
        priceTwo: "0.0556",
        priceThree: "0.0327",
        priceFour: "0.0359",
        image: "https://flagcdn.com/w320/ng.png",
    },
    {
        value: "Pakistan",
        label: "Pakistan",
        priceOne: "0.0182",
        priceTwo: "0.0513",
        priceThree: "0.0268",
        priceFour: "0.0293",
        image: "https://flagcdn.com/w320/pk.png",
    },
    {
        value: "Perú",
        label: "Perú",
        priceOne: "0.0219",
        priceTwo: "0.0743",
        priceThree: "0.0417",
        priceFour: "0.0459",
        image: "https://flagcdn.com/w320/pe.png",
    },
    {
        value: "Russia",
        label: "Russia",
        priceOne: "0.0438",
        priceTwo: "0.0842",
        priceThree: "0.0469",
        priceFour: "0.0517",
        image: "https://flagcdn.com/w320/ru.png",
    },
    {
        value: "Saudi Arabia",
        label: "Saudi Arabia",
        priceOne: "0.0235",
        priceTwo: "0.0446",
        priceThree: "0.0266",
        priceFour: "0.0292",
        image: "https://flagcdn.com/w320/sa.png",
    },
    {
        value: "South Africa",
        label: "South Africa",
        priceOne: "0.0208",
        priceTwo: "0.0419",
        priceThree: "0.0356",
        priceFour: "0.0240",
        image: "https://flagcdn.com/w320/za.png",
    },
    {
        value: "Spain",
        label: "Spain",
        priceOne: "0.0409",
        priceTwo: "0.0655",
        priceThree: "0.0382",
        priceFour: "0.0420",
        image: "https://flagcdn.com/w320/es.png",
    },
    {
        value: "Turkey",
        label: "Turkey",
        priceOne: "0.007",
        priceTwo: "0.0149",
        priceThree: "0.0123",
        priceFour: "0.0133",
        image: "https://flagcdn.com/w320/tr.png",
    },
    {
        value: "United Arab Emirates",
        label: "United Arab Emirates",
        priceOne: "0.0230",
        priceTwo: "0.0380",
        priceThree: "0.0218",
        priceFour: "0.0238",
        image: "https://flagcdn.com/w320/ae.png",
    },
    {
        value: "United Kingdom",
        label: "United Kingdom",
        priceOne: "0.0428",
        priceTwo: "0.0745",
        priceThree: "0.0398",
        priceFour: "0.0438",
        image: "https://flagcdn.com/w320/gb.png",
    },
    {
        label: "North America",
        priceOne: "0.0128",
        priceTwo: "0.0290",
        priceThree: "0.0175",
        priceFour: "0.0190",
        image: "",
    },
    {
        label: "Rest of Africa",
        priceOne: "0.0403",
        priceTwo: "0.0265",
        priceThree: "0.0184",
        priceFour: "0.0200",
        image: "",
    },
    {
        label: "Rest of Asia Pacific",
        priceOne: "0.0264",
        priceTwo: "0.0772",
        priceThree: "0.0465",
        priceFour: "0.0512",
        image: "",
    },
    {
        label: "Rest of Central & Eastern Europe",
        priceOne: "0.0290",
        priceTwo: "0.0900",
        priceThree: "0.0597",
        priceFour: "0.0659",
        image: "",
    },
    {
        label: "Rest of Latin America",
        priceOne: "0.0463",
        priceTwo: "0.0780",
        priceThree: "0.0485",
        priceFour: "0.0534",
        image: "",
    },
    {
        label: "Rest of Middle East",
        priceOne: "0.0258",
        priceTwo: "0.0381",
        priceThree: "0.0218",
        priceFour: "0.0238",
        image: "",
    },
    {
        label: "Rest of Western Europe",
        priceOne: "0.0437",
        priceTwo: "0.0632",
        priceThree: "0.0418",
        priceFour: "0.0460",
        image: "",
    },
    {
        label: "Other",
        priceOne: "0.0185",
        priceTwo: "0.0644",
        priceThree: "0.0344",
        priceFour: "0.0378",
        image: "",
    },
]

const Calculador = () => {

    //Estado para valor de Precios por conversaciones de marketing
    let [priceMarketing, setPriceMarketing] = useState("0.0743")
    //Estado para valor de Precios por conversaciones de autenticación
    let [priceAuthentication, setPriceAuthentication] = useState("0.0417")
    //Estado para valor de Precios por conversaciones de utilidad
    let [priceUtility, setPriceUtility] = useState("0.0459")

    const handleChangeCountry = e => {
        const countryFound = countries.find(item => item.label === e.label)
        setPriceMarketing(countryFound.priceTwo)
        setPriceAuthentication(countryFound.priceThree)
        setPriceUtility(countryFound.priceFour)
    }


    // conversión precios por conversaciones de marketing
    const [conversationPriceMarketing, setConversationPriceMarketing] = useState(
        0
    )
    const changeConversationPriceMarketing = event => {
        setConversationPriceMarketing(event.target.value)
    }

    // conversión precios por conversaciones de authentication
    const [
        conversationPriceAuthentication,
        setConversationPriceAuthentication,
    ] = useState(0)
    const changeConversationPriceAuthentication = event => {
        setConversationPriceAuthentication(event.target.value)
    }

    // conversión precios por conversaciones de utilidad
    const [conversationPriceUtility, setConversationPriceUtility] = useState(0)
    const changeConversationPriceUtility = event => {
        setConversationPriceUtility(event.target.value)
    }

    const textDescripton = (
        <p>
            {checked}
            Todas las conversaciones <strong> iniciadas por el usuario </strong> son
            categorizadas como conversaciones de servicio, y nacen a partir de que un
            usuario le habla al WhatsApp de tu negocio.
            <br />
            <br />
            {checked} Las conversaciones <strong>iniciadas por las empresas</strong>,
            que nacen cuando el negocio envía una plantilla a los usuarios, se
            clasifican en estas categorías: Marketing, autenticación y utilidad.
            <br />
            <br />
            {checked} Al iniciar una conversación, WhatsApp abre una ventana de 24
            horas.{" "}
            <strong>
                Cada tipo de conversación se considerará una ventana independiente de 24
                horas
            </strong>
            . Si se abre una ventana de marketing a las 11:30am del día 1 y luego una
            de utilidad a las 05:40pm del mismo día, ambas se cobrarán por separado.
            Pero si se envían plantillas del mismo tipo en el rango de 24 horas, solo
            se cobra la primera enviada.
            <br />
            <br />
            {checked} Por <strong>cada mensaje enviado o recibido de WhatsApp</strong>{" "}
            se suma un costo de USD $0.003. Puedes aplicar a un fee ilimitado mensual
            de $200.
        </p>
    )

    const [textConversacion, settextConversacion] = useState("conversacion")
    useEffect(() => {
        let windowWidth = window.screen.width
        if (windowWidth < 600) {
            settextConversacion("conv.")
        } else {
            settextConversacion("conversacion")
        }
    }, [])

    const [totalPrice, setTotalPrice] = useState(0.0)

    useEffect(() => {
        setTotalPrice(
            parseFloat(priceMarketing) * conversationPriceMarketing +
            parseFloat(priceAuthentication) * conversationPriceAuthentication +
            parseFloat(priceUtility) * conversationPriceUtility
        )
    }, [
        priceMarketing,
        priceAuthentication,
        priceUtility,
        conversationPriceAuthentication,
        conversationPriceMarketing,
        conversationPriceUtility,
    ])

    return (
        <section className="container-calculador-whatsapp-precio">
            <p className="container-calculador-whatsapp-precio-title">
                WhatsApp Business API potencia y <TitleUnderlineOne underline="automatiza los" /> <TitleUnderlineTwo underline="casos de uso" />  más implementados del mercado
            </p>


            <div className="container-calculator-price-whatsapp">

                <div className="container-calculator-price-whatsapp-description">
                    <div className="select-country-whatsapp">
                        <p>Selecciona el país de destino</p>

                        <Select
                            options={countries}
                            defaultValue={{
                                value: "Perú",
                                label: "Perú",
                                image: "https://flagcdn.com/w320/pe.png",
                            }}
                            onChange={value => handleChangeCountry(value)}
                            placeholder={"Seleccione un país"}
                            formatOptionLabel={country => (
                                <div className="country-option">
                                    <img
                                        src={country.image ? country.image : imageGlobo}
                                        alt="country-image"
                                    />
                                    <span>{country.label}</span>
                                </div>
                            )}

                        />
                    </div>
                    <p className="container-calculator-price-whatsapp-description-nota">
                        Recuerda que este esquema de precios viene desde el mismo WhatsApp.
                        ¿La diferencia con Beex? Que no sumamos un fee alto a diferencia
                        de otros proveedores/BSP.
                    </p>
                    <div className="container-calculator-price-whatsapp-description-Desktop">
                        {textDescripton}
                    </div>
                </div>

                <div className="container-calculator-price-whatsapp-calculador">
                    <div>
                        <p className="container-calculator-price-whatsapp-calculador-texttotal">
                            Estimado
                        </p>
                        <p className="container-calculator-price-whatsapp-calculador-total">
                            {" "}
                            ${totalPrice.toFixed(4)}
                        </p>
                    </div>

                    <div>
                        <p className="container-calculator-price-whatsapp-calculador-textprice">
                            Precios por <strong>conversaciones de marketing</strong>
                            <div className="tooltip">
                                <span className="tooltiptext">
                                    Plantillas que incluyen promociones u ofertas, actualizaciones
                                    informativas o invitaciones para que los clientes respondan o
                                    tomen acción.
                                </span>
                                {info}
                            </div>
                        </p>
                        <div className="container-calculator-price-whatsapp-calculador-detail">
                            <p>US${priceMarketing} / 24 horas</p>
                        </div>
                        <input
                            type="range"
                            onChange={changeConversationPriceMarketing}
                            min={0}
                            max={10001}
                            step={10}
                            value={conversationPriceMarketing}
                            className="range-slider__range"
                        />
                        <div className="container-calculator-price-whatsapp-calculador-span">
                            <p>
                                {Number(conversationPriceMarketing)} {textConversacion}
                            </p>
                            <p>10,000+ {textConversacion}</p>
                        </div>
                    </div>
                    <br />
                    <div>
                        <p className="container-calculator-price-whatsapp-calculador-textprice">
                            Precios por <strong>conversaciones de autenticación</strong>
                            <div className="tooltip">
                                <span className="tooltiptext">
                                    Plantillas que permiten a las empresas autenticar a los usuarios
                                    con contraseñas de un solo uso. Por ejemplo, verificación de
                                    cuentas, recuperación de cuentas, desafíos de integridad.
                                </span>
                                {info}
                            </div>
                        </p>
                        <div className="container-calculator-price-whatsapp-calculador-detail">
                            <p>US${priceAuthentication} / 24 horas</p>
                        </div>
                        <input
                            type="range"
                            onChange={changeConversationPriceAuthentication}
                            min={0}
                            max={10001}
                            step={10}
                            value={conversationPriceAuthentication}
                            className="range-slider__range"
                        />
                        <div className="container-calculator-price-whatsapp-calculador-span">
                            <p>
                                {Number(conversationPriceAuthentication)} {textConversacion}
                            </p>
                            <p>10,000+ {textConversacion}</p>
                        </div>
                    </div>
                    <br />
                    <div>
                        <p className="container-calculator-price-whatsapp-calculador-textprice">
                            Precios por <strong>conversaciones de utilidad</strong>
                            <div className="tooltip">
                                <span className="tooltiptext">
                                    Plantillas para solicitudes o transacciones específicas,
                                    incluyendo actualizaciones a clientes sobre transacciones en
                                    curso, notificaciones de compra, extracción de facturaciones y
                                    más.
                                </span>
                                {info}
                            </div>
                        </p>
                        <div className="container-calculator-price-whatsapp-calculador-detail">
                            <p>US${priceUtility} / 24 horas</p>
                        </div>
                        <input
                            type="range"
                            onChange={changeConversationPriceUtility}
                            min={0}
                            max={10001}
                            step={10}
                            value={conversationPriceUtility}
                            className="range-slider__range"
                        />
                        <div className="container-calculator-price-whatsapp-calculador-span">
                            <p>
                                {Number(conversationPriceUtility)} {textConversacion}
                            </p>
                            <p>10,000+ {textConversacion}</p>
                        </div>
                    </div>
                    <br />
                    <p className="text-center">
                        <strong>¿Quieres entenderlo mejor o más información?</strong>
                    </p>
                    <br />
                    <br />
                    <p className="text-center">
                        <Link
                            className="btn-calculator-pricing"
                            href={`/whatsapp-business-api/contacto-whatsapp-api/`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ¡Habla con un experto!
                        </Link>
                    </p>
                </div>

                <div className="container-calculator-price-whatsapp-description-Mobile">
                    {textDescripton}
                </div>
            </div>
        </section>
    )
}

export default Calculador
