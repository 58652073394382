import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="150" height="9" viewBox="0 0 150 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.7837 8.33102C24.6997 7.42759 2.08748 8.25375 0.439322 7.99818C-0.90917 7.64156 1.16352 1.47805 2.39964 0.568684C2.83665 0.24773 3.34855 0.18235 4.52224 0.354714C5.45869 0.485473 11.9015 0.467639 18.1195 0.402259C24.3376 0.33688 30.3309 0.259614 30.3309 0.259614C30.643 0.455753 31.6045 -0.180211 31.7793 0.0515895C32.054 0.324995 41.8929 0.372544 52.0815 0.372544C62.2701 0.372544 72.8083 0.354711 74.4565 0.360654C76.4667 0.366598 74.8561 0.717274 77.2284 0.610289C82.2728 0.384432 93.2355 0.883691 97.0562 0.616229C100.128 0.40226 100.777 0.414147 103.699 0.681609C106.67 0.955015 122.603 0.996621 124.451 0.622174C124.9 0.515189 125.237 0.562737 125.237 0.699439C125.449 0.71727 136.437 1.11549 136.475 1.21653C136.55 1.35324 136.887 1.4305 137.211 1.34135C137.811 1.21059 149.785 1.01445 149.935 1.41862C150.397 2.57762 148.311 7.38004 146.851 8.5331C145.315 9.75154 137.711 8.12894 131.418 8.73518C97.0937 8.00412 103.924 8.06356 96.1697 8.50338C95.483 8.07544 92.7111 9.04425 91.0629 8.29536C90.3887 7.98629 85.4567 7.86742 84.333 8.14677C83.0968 8.45584 58.387 7.30277 55.4902 8.17054C50.8954 7.40976 32.1289 8.19432 27.7837 8.33102Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="257" height="9" viewBox="0 0 257 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M47.6028 8.33102C42.3188 7.42759 3.57654 8.25375 0.752704 7.99818C-1.55771 7.64156 1.9935 1.47805 4.11138 0.568684C4.86012 0.24773 5.73719 0.18235 7.7481 0.354714C9.35256 0.485473 20.3912 0.467639 31.0448 0.402259C41.6984 0.33688 51.9669 0.259614 51.9669 0.259614C52.5017 0.455753 54.149 -0.180211 54.4485 0.0515895C54.9191 0.324995 71.7765 0.372544 89.233 0.372544C106.689 0.372544 124.745 0.354711 127.569 0.360655C131.013 0.366598 128.253 0.717274 132.318 0.610289C140.961 0.384432 159.743 0.883691 166.29 0.616229C171.552 0.40226 172.665 0.414147 177.671 0.681609C182.762 0.955015 210.059 0.996621 213.225 0.622174C213.995 0.515189 214.573 0.562737 214.573 0.699439C214.937 0.71727 233.762 1.11549 233.827 1.21653C233.955 1.35324 234.532 1.4305 235.089 1.34135C236.116 1.21059 256.631 1.01445 256.888 1.41862C257.679 2.57762 254.107 7.38004 251.604 8.5331C248.973 9.75154 235.944 8.12894 225.162 8.73518C166.354 8.00412 178.056 8.06356 164.771 8.50338C163.594 8.07544 158.845 9.04425 156.021 8.29536C154.866 7.98629 146.416 7.86742 144.49 8.14677C142.373 8.45584 100.036 7.30277 95.0732 8.17054C87.2007 7.40976 55.0474 8.19432 47.6028 8.33102Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineIntro = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="247" height="11" viewBox="0 0 247 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M45.7341 10.1616C40.6515 9.21528 3.40841 10.1201 0.702613 9.83073C-1.5059 9.44779 2.04896 2.85402 4.10363 1.88846C4.82386 1.54129 5.65934 1.49339 7.57256 1.65911C10.6061 1.96868 49.846 1.516 49.846 1.516C50.3634 1.7213 51.9201 1.0401 52.2047 1.28908C53.1014 1.87086 116.703 1.30718 122.127 1.29456C125.416 1.28711 122.813 1.67212 126.684 1.52684C134.932 1.23807 152.906 1.60679 159.156 1.26646C164.158 0.98529 165.237 0.976394 170.036 1.23497C174.941 1.46849 201.027 1.24911 204.01 0.795494C204.74 0.670907 205.301 0.717399 205.3 0.86364C205.3 0.895431 223.687 1.06849 223.771 1.18324C223.908 1.32997 224.468 1.40188 224.987 1.30198C225.949 1.15279 245.523 0.668777 245.828 1.09586C246.786 2.32005 244.071 7.51158 241.793 8.76884C239.462 10.1149 226.695 8.55018 216.425 9.32776L168.623 9.2415L158.375 9.71394C157.203 9.27107 152.685 10.3615 149.959 9.56339C148.808 9.24776 140.667 9.17448 138.868 9.49876C137.302 9.74756 95.6559 8.95819 91.3811 9.8269C83.7709 9.06243 52.8929 10.0088 45.7341 10.1616Z" fill="#FFA700"/>
</svg>
  </span>
)


