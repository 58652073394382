import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="123" height="11" viewBox="0 0 123 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.8126 9.59711C19.9689 8.77612 1.48226 11.1114 0.423051 10.9958C-0.698775 10.7283 0.657853 4.28803 1.62836 3.26636C2.22319 2.64605 2.18145 3.09608 7.31054 2.64605C7.31054 2.64605 15.0433 1.98926 24.5501 1.223C24.5501 1.223 39.8226 0.231727 60.8504 0.000633187C62.5044 -0.0176111 61.1947 0.365518 63.141 0.231727C67.2891 -0.0419367 76.3159 0.474984 79.457 0.256053C81.9772 0.0796917 82.5199 0.0675293 84.9253 0.402007C86.5793 0.633101 100.459 1.20475 101.998 0.858114C102.374 0.772974 102.645 0.827706 102.64 0.967579C102.749 0.979742 111.844 1.8129 111.886 1.94061C111.948 2.08656 112.225 2.17778 112.491 2.1048C112.981 1.96493 122.817 2.39671 122.947 2.82241C123.323 4.0387 121.606 8.84302 120.401 9.95592C119.154 11.1053 113.107 9.08627 107.764 9.43291C82.8434 7.6389 83.0364 8.095 78.8726 8.33218C78.2987 7.88823 76.0341 8.84302 74.6879 8.08284C74.1244 7.76052 70.0754 7.6389 69.1675 7.91256C68.9745 7.97337 67.5291 7.96121 65.442 7.91864C64.3985 7.9004 63.1931 7.86999 61.9043 7.84566C60.1825 7.80917 47.1536 7.64498 45.5205 8.35042C41.1271 7.64498 26.9921 9.17141 22.8126 9.59711Z" fill="#FFBA00"/>
</svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="263" height="10" viewBox="0 0 263 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.8958 10C43.4584 9.07169 3.64439 10.1081 0.750473 9.82831C-1.61214 9.45317 2.16352 2.84689 4.35657 1.87407C5.12527 1.52436 6.01831 1.47351 8.0644 1.63247C11.3088 1.93131 53.2593 1.33997 53.2593 1.33997C53.8132 1.54344 55.475 0.856744 55.7802 1.10472C56.741 1.68332 124.737 0.894891 130.536 0.8631C134.052 0.844025 131.271 1.23824 135.408 1.07928C144.226 0.761364 163.443 1.06657 170.124 0.704142C175.471 0.405302 176.624 0.392594 181.756 0.63421C187.001 0.850392 214.889 0.538832 218.077 0.074676C218.857 -0.0524901 219.456 -0.00797814 219.456 0.138263C219.456 0.170054 239.115 0.278142 239.205 0.392591C239.352 0.538832 239.951 0.608769 240.505 0.507036C241.534 0.354437 262.458 -0.198737 262.786 0.227269C263.815 1.44806 260.932 6.64915 258.502 7.91446C256.015 9.26878 242.359 7.74915 231.382 8.56302L180.275 8.64568L169.321 9.15433C168.067 8.71561 163.24 9.82196 160.323 9.03353C159.091 8.72197 150.386 8.67745 148.465 9.00809C146.792 9.26242 102.264 8.62023 97.6968 9.50403C89.5576 8.76647 56.5489 9.82197 48.8958 10Z" fill="#FFA700" />
    </svg>
  </span>
)
