import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Automatiza el contacto",
      description: "Personaliza mensajes y programa llamadas de seguimiento para mantener el interés del cliente y acelerar el proceso de ventas. Mantén una comunicación constante y optimiza los resultados comerciales.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Utiliza marcadores inteligentes",
      description: "Llama automáticamente a toda la base de datos o segmentos específicos, clasificando solo llamadas contestadas por humanos y filtrando números inactivos, mejorando así la eficiencia de cada campaña. ",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Seguimiento automatizado",
      description: "Automatiza el seguimiento durante el ciclo de vida del cliente. Envía notificaciones por WhatsApp y programa recordatorios para que los agentes contacten a clientes potenciales en el momento ideal. ",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
    {
      title: "Dashboards dinámicos",
      description: "Los paneles personalizables muestran datos en tiempo real sobre el rendimiento de campañas, con métricas clave como tiempos de espera y niveles de servicio. Filtra según roles y objetivos para insights precisos. ",
      image:imageThreeMobile ,
      background: "#FBE6E5"
    },
  ]

  return (
    <section className="container-experience-voiceApi">
      <p className="container-experience-voiceApi-title">
      Elige la plataforma correcta para tus   <TitleUnderlineOne underline="procesos inbound" /> <TitleUnderlineTwo underline="y outbound" />
      </p>


      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 0px 0px" }}>
          <section style={{padding: "0px 32px" }}>
            <p className="container-experience-voiceApi-cardsOne-card-title">Automatiza el contacto </p>
            <p className="container-experience-voiceApi-cardsOne-card-description">
            Personaliza mensajes y programa llamadas de seguimiento para mantener el interés del cliente y acelerar el proceso de ventas. Mantén una comunicación constante y optimiza los resultados comerciales.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-voiceApi-cardsOne-column">
          {/* columna */}
          <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-voiceApi-cardsOne-card-title">Utiliza marcadores inteligentes</p>
              <p className="container-experience-voiceApi-cardsOne-card-description">
              Llama automáticamente a toda la base de datos o segmentos específicos, clasificando solo llamadas contestadas por humanos y filtrando números inactivos, mejorando así la eficiencia de cada campaña.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

<br /><br />
      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsTwo">
        {/* columna */}
        <div className="container-experience-voiceApi-cardsTwo-cards-column">
          <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#EEEAFB" }}>
            <section>
              <p className="container-experience-voiceApi-cardsTwo-card-title">Seguimiento automatizado</p>
              <p className="container-experience-voiceApi-cardsTwo-card-description">
              Automatiza el seguimiento durante el ciclo de vida del cliente. Envía notificaciones por WhatsApp y programa recordatorios para que los agentes contacten a clientes potenciales en el momento ideal.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsTwo-card-image"
                src={imageThreeDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#FBE6E5"}}>
          <section >
            <p className="container-experience-voiceApi-cardsTwo-card-title">Dashboards dinámicos</p>
            <p className="container-experience-voiceApi-cardsTwo-card-description">
            Los paneles personalizables muestran datos en tiempo real sobre el rendimiento de campañas, con métricas clave como tiempos de espera y niveles de servicio. Filtra según roles y objetivos para insights precisos.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsTwo-card-image"
              src={imageFourDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>


      <div className="container-experience-voiceApi-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-voiceApi-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-voiceApi-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-voiceApi-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-voiceApi-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
