import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="162" height="9" viewBox="0 0 162 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.0064 8.33102C26.6756 7.42759 2.25448 8.25375 0.474467 7.99818C-0.981903 7.64156 1.2566 1.47805 2.59161 0.568684C3.06358 0.24773 3.61644 0.18235 4.88402 0.354714C5.89539 0.485473 12.8536 0.467639 19.5691 0.402259C26.2846 0.33688 32.7573 0.259614 32.7573 0.259614C33.0945 0.455753 34.1328 -0.180211 34.3216 0.0515895C34.6183 0.324995 45.2444 0.372544 56.248 0.372544C67.2517 0.372544 78.633 0.354711 80.413 0.360654C82.5841 0.366598 80.8445 0.717274 83.4067 0.610289C88.8546 0.384432 100.694 0.883691 104.821 0.616229C108.138 0.40226 108.839 0.414147 111.995 0.681609C115.204 0.955015 132.411 0.996621 134.407 0.622174C134.892 0.515189 135.256 0.562737 135.256 0.699439C135.485 0.71727 147.352 1.11549 147.393 1.21653C147.474 1.35324 147.838 1.4305 148.188 1.34135C148.835 1.21059 161.768 1.01445 161.929 1.41862C162.428 2.57762 160.176 7.38004 158.599 8.5331C156.94 9.75154 148.728 8.12894 141.931 8.73518C104.861 8.00412 112.237 8.06356 103.863 8.50338C103.122 8.07544 100.128 9.04425 98.3479 8.29536C97.6198 7.98629 92.2932 7.86742 91.0796 8.14677C89.7446 8.45584 63.0579 7.30277 59.9294 8.17054C54.967 7.40976 34.6992 8.19432 30.0064 8.33102Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="291" height="9" viewBox="0 0 291 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53.9004 8.33102C47.9174 7.42759 4.04971 8.25375 0.852284 7.99818C-1.76379 7.64156 2.25723 1.47805 4.6553 0.568684C5.5031 0.24773 6.49619 0.18235 8.77314 0.354714C10.5899 0.485473 23.0889 0.467639 35.1519 0.402259C47.2149 0.33688 58.8419 0.259614 58.8419 0.259614C59.4475 0.455753 61.3126 -0.180211 61.6518 0.0515895C62.1847 0.324995 81.2723 0.372544 101.038 0.372544C120.804 0.372544 141.248 0.354711 144.446 0.360654C148.345 0.366598 145.221 0.717274 149.823 0.610289C159.609 0.384432 180.877 0.883691 188.289 0.616229C194.248 0.40226 195.508 0.414147 201.176 0.681609C206.941 0.955015 237.849 0.996621 241.434 0.622174C242.306 0.515189 242.96 0.562737 242.96 0.699439C243.372 0.71727 264.688 1.11549 264.761 1.21653C264.906 1.35324 265.56 1.4305 266.19 1.34135C267.353 1.21059 290.582 1.01445 290.873 1.41862C291.769 2.57762 287.724 7.38004 284.89 8.5331C281.911 9.75154 267.159 8.12894 254.95 8.73518C188.362 8.00412 201.612 8.06356 186.569 8.50338C185.237 8.07544 179.859 9.04425 176.662 8.29536C175.354 7.98629 165.786 7.86742 163.606 8.14677C161.208 8.45584 113.271 7.30277 107.651 8.17054C98.737 7.40976 62.33 8.19432 53.9004 8.33102Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineIntro = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="247" height="11" viewBox="0 0 247 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.7341 10.1616C40.6515 9.21528 3.40841 10.1201 0.702613 9.83073C-1.5059 9.44779 2.04896 2.85402 4.10363 1.88846C4.82386 1.54129 5.65934 1.49339 7.57256 1.65911C10.6061 1.96868 49.846 1.516 49.846 1.516C50.3634 1.7213 51.9201 1.0401 52.2047 1.28908C53.1014 1.87086 116.703 1.30718 122.127 1.29456C125.416 1.28711 122.813 1.67212 126.684 1.52684C134.932 1.23807 152.906 1.60679 159.156 1.26646C164.158 0.98529 165.237 0.976394 170.036 1.23497C174.941 1.46849 201.027 1.24911 204.01 0.795494C204.74 0.670907 205.301 0.717399 205.3 0.86364C205.3 0.895431 223.687 1.06849 223.771 1.18324C223.908 1.32997 224.468 1.40188 224.987 1.30198C225.949 1.15279 245.523 0.668777 245.828 1.09586C246.786 2.32005 244.071 7.51158 241.793 8.76884C239.462 10.1149 226.695 8.55018 216.425 9.32776L168.623 9.2415L158.375 9.71394C157.203 9.27107 152.685 10.3615 149.959 9.56339C148.808 9.24776 140.667 9.17448 138.868 9.49876C137.302 9.74756 95.6559 8.95819 91.3811 9.8269C83.7709 9.06243 52.8929 10.0088 45.7341 10.1616Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineIntroTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="150" height="9" viewBox="0 0 150 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.7837 8.33102C24.6997 7.42759 2.08748 8.25375 0.439322 7.99818C-0.90917 7.64156 1.16352 1.47805 2.39964 0.568684C2.83665 0.24773 3.34855 0.18235 4.52224 0.354714C5.45869 0.485473 11.9015 0.467639 18.1195 0.402259C24.3376 0.33688 30.3309 0.259614 30.3309 0.259614C30.643 0.455753 31.6045 -0.180211 31.7793 0.0515895C32.054 0.324995 41.8929 0.372544 52.0815 0.372544C62.2701 0.372544 72.8083 0.354711 74.4565 0.360654C76.4667 0.366598 74.8561 0.717274 77.2284 0.610289C82.2728 0.384432 93.2355 0.883691 97.0562 0.616229C100.128 0.40226 100.777 0.414147 103.699 0.681609C106.67 0.955015 122.603 0.996621 124.451 0.622174C124.9 0.515189 125.237 0.562737 125.237 0.699439C125.449 0.71727 136.437 1.11549 136.475 1.21653C136.55 1.35324 136.887 1.4305 137.211 1.34135C137.811 1.21059 149.785 1.01445 149.935 1.41862C150.397 2.57762 148.311 7.38004 146.851 8.5331C145.315 9.75154 137.711 8.12894 131.418 8.73518C97.0937 8.00412 103.924 8.06356 96.1697 8.50338C95.483 8.07544 92.7111 9.04425 91.0629 8.29536C90.3887 7.98629 85.4567 7.86742 84.333 8.14677C83.0968 8.45584 58.387 7.30277 55.4902 8.17054C50.8954 7.40976 32.1289 8.19432 27.7837 8.33102Z" fill="#FFA700" />
    </svg>
  </span>
)