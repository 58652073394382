import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { Link } from "gatsby"
import TitleUnderline from "./titleUnderline"

const SilderPricing = ({ data, thanks }) => {
  //funcion para slider de logos clientes
  var settings = {
    slidesToShow: 3,
    infinite: false,
    initialSlide: 0,
    dots: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
        },
      },
    ],
  }
  return (
    <div className={`${thanks ? "" : "container-silder-pricing-pagesSolucion"} container-silder-pricing-pagesThanks  `} >

      <section className="silder-price-ticker">
        <p className="container-conversions-title">
          ¡Empieza ahora con cualquiera de <TitleUnderline underline="nuestros planes!" />  </p>

        <section>
          <section className="silder-pricing">
            <Slider {...settings}>
              {data.map((elemento, i) => {
                return (
                  <div
                    className={`card-ticker-princing  ${Detail(elemento)} `} key={i}>
                    <div className="cards-inbox-functions">
                      <div className="card-title-pricing" >
                        <img
                          loading="lazy"
                          src={elemento.img}
                          className="card-icon"
                          alt="..."
                        />
                        <p className="card-title-pricing-inbox">
                          {elemento.title}
                        </p>
                        <div>{elemento.price}</div>

                        <hr style={{ width: "100%" }} />
                      </div>
                      <div className="container-btn-silder">
                        <Link
                          className={"btn-slider-price-white"}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={elemento.link}
                        >
                          Empezar
                        </Link>
                      </div>
                      <div className="container-items-price">
                        {elemento.info.map(item => {
                          return (
                            <div className="items-price" key={item}>
                              <img src={elemento.checked} alt="img-price" />
                              <p>{item}</p>
                            </div>
                          )
                        })}
                      </div>

                    </div>
                  </div>
                )
              })}
            </Slider>
          </section>
          <div className="link-pricing">
            <Link href="/conversations/precios/">Ver comparación completa</Link>
          </div>
        </section>
      </section>
    </div>
  )
}
export default SilderPricing



const Detail = (elemento) => {
  if (elemento.title === "FREE") {
    return "border-free"
  }
  if (elemento.title === "ADVANCED") {
    return "border-avanced"
  }
  if (elemento.title === "BUSINESS") {
    return "border-busines"
  }
  if (elemento.title === "ENTERPRISE") {
    return "border-enterprise"
  }
}