import React from "react"
import { Link } from "gatsby"
import helpCustomer from "./images/imagenContact.webp"
import contactOne from "./images/contactOne.webp"
import contactTwo from "./images/contactTwo.webp"
import contactThree from "./images/contactThree.webp"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import TitleUnderline from "./titleUnderline"

const IntroHome = ({ data, location }) => {
  return (
    <section className="container-intro-page-home">
      <div className="container-intro-page-home-infoTitle">
          <h1 className="container-intro-page-home-infoTitle-title">
          <TitleUnderline underline="Tecnología omnicanal" />  impulsada por IA para servicio al cliente,
            marketing y ventas
          </h1>
          <div className="container-intro-page-home-infoTitle-text">
            Hacemos que las empresas se contacten y comuniquen mejor con sus
            usuarios, con soluciones omnicanales, automatizaciones e IA.
            Aplicamos para grandes operaciones de Contact Center y negocios que
            buscan escalar.
          </div>
          <p>
            <Link
              className="container-intro-page-home-infoTitle-button"
              to={`/hablemos/${location.search}`}
            >
              Comunícate con ventas
            </Link>
          </p>
      </div>
      <div className="container-intro-page-home-image">
        <img
          src={helpCustomer}
          alt="customer"
          loading="lazy"
        />
      </div>
      
      <section className="container-intro-page-home-silder">
        <Slider
          dots={true}
          infinite={true}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          speed={500}
          autoplaySpeed={18000}
          pauseOnFocus={false}
          draggable={true}
          pauseOnHover={false}
          initialSlide={0}
          arrows={true}
        >
          <img
            src={contactOne}
            alt="customer"
            className="image-help-customermobile"
            loading="lazy"
          />
          <img
            src={contactTwo}
            alt="customer"
            className="image-help-customermobile"
            loading="lazy"
          />
          <img
            src={contactThree}
            alt="customer"
            className="image-help-customermobile"
            loading="lazy"
          />
        </Slider>
      </section>
    </section>
  )
}

export default IntroHome
