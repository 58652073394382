import React from "react"

import { Link } from "gatsby"
const Intro = ({ data, location }) => {
  return (
    <section className="container-start-alternative-telemarketing">
      <section className="section-text-alternative-telemarketing">
        <p className="title-start-alternative-telemarketing">{data.title}</p>
        <br />
        <p className="text-start-alternative-telemarketing">{data.textbody}</p>
        <br />
        <Link
          className="button-start-alternative-telemarketing"
          href={`/hablemos/${location.search}`}
        >
         {data.textbutton}
        </Link>
      </section>
      <section className="container-start-partners-img-section">
        <img
          loading="lazy"
          className="container-image-alternative-whatsapp"
          src={data.image}
          alt="animacion"
        />
      </section>
    </section>
  )
}

export default Intro
