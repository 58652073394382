/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import hexagono from "./hexagonogrande.png"

const BannerContact = ({ data, location, link }) => {
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const [windowsWidth, setWindowsWidth] = useState(true)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 624) {
      setWindowsWidth(false)
    } else {
      setWindowsWidth(true)
    }
  }, [])

  return (
    <div className="container-banner-contact">
      {windowsWidth ? (
        <div className="container-banner-contact-hexagonos">
          <img
            src={hexagono}
            alt="icon-hexagono"
            className="container-banner-contact-hexagonos-hexagonoOne"
          />
          <img
            src={hexagono}
            alt="icon-hexagono"
            className="container-banner-contact-hexagonos-hexagonoTwo"
          />
          <img
            src={hexagono}
            alt="icon-hexagono"
            className="container-banner-contact-hexagonos-hexagonoThree"
          />
        </div>
      ) : (
        ""
      )}
      <section className="container-banner-contact-info">

          <p className="container-banner-contact-info-title">{data.title}</p>
          <p className="container-banner-contact-info-text">{data.textbody}</p>
            <Link className="container-banner-contact-info-button" href={`${data.href}${location.search}`} >
              {data.btn}
            </Link>       
      </section>
    </div>
  )
}
export default BannerContact
