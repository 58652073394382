/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import FlorayFauna from "@components/sharedComponents/customersLogos/logos/FlorayFauna.svg"
import InClick from "@components/sharedComponents/customersLogos/logos/InClick.svg"
import Fritz from "@components/sharedComponents/customersLogos/logos/Fritz.svg"
import Ultralon from "@components/sharedComponents/customersLogos/logos/Ultralon.svg"
import CasaRossello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"
import PlayNTrade from "@components/sharedComponents/customersLogos/logos/PlayNTrade.svg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageAbm/pageCoolbox/image/imageOne.png"
import ImageAccordionLeftTwo from "@components/pageAbm/pageCoolbox/image/imageTwo.png"
import ImageAccordionLeftThree from "@components/pageAbm/pageCoolbox/image/imageThree.png"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageCoolbox.jpg"
import imgStart from "@components/pageAbm/pageCoolbox/image/imageLogo.svg"
import imgBanner from "@components/pageAbm/pageCoolbox/image/banner.png"

const titleStart = (<p className="guide__title">

    Hola, Coolbox. Continúa fortaleciendo la  <span style={{ color: '#E30F2C' }}>expansión del canal digital</span>  de la marca automatizando cada fase del embudo de conversión
</p>
)

const descripcionStart = (<p>
    Descubre cómo Beex puede potenciar las ventas de Coolboox con tecnología omnicanal impulsada por IA. Personaliza cada etapa del recorrido del cliente y mejora su experiencia en todos los canales de contacto.
    <br /><br />
    Simplifica tu gestión automatizando procesos con chatbots inteligentes, enviando ofertas personalizadas, gestionando envíos y optimizando el soporte de tu e-commerce de forma eficiente.
</p>)

const data = {
    start: {
        title: titleStart,
        textbody: descripcionStart,
        img: imgStart,
        banner: imgBanner,
        inputText: "Puesto en Coolbox",
    },
    customersDesktop: [
        FlorayFauna,
        InClick,
        Fritz,
        Ultralon,
        CasaRossello,
        PlayNTrade
    ],
    customersTablet: {
        arrayOne: [FlorayFauna,
            InClick,
            Fritz,
            Ultralon,
            CasaRossello,
            PlayNTrade],
    },
    contacts: {
        title: "Aumenta las conversiones y fideliza más clientes en Coolbox",
        textbody: "Estás a un paso de revolucionar tus estrategias de marketing y ventas de tu e-commerce. Agenda una reunión para que uno de nuestros agentes se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    accordionRight: [
        {
            title: "Integra tus canales con Vtex",
            description: "<p>Integra todos tus canales de atención con Vtex y transforma la experiencia de tus clientes. Con Beex, puedes conectar WhatsApp, redes sociales, chatbots y más, directamente con tu plataforma de e-commerce. <br /><br /> Esto te permite ofrecer una experiencia omnicanal fluida, asegurando que cada interacción, desde consultas hasta compras, sea consistente y eficiente. Centralizar estos puntos de contacto optimiza tus recursos y mejora la satisfacción del cliente en cada paso del proceso.</p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Utiliza chatbots con IA",
            description: "<p>Optimiza la atención al cliente utilizando chatbots con inteligencia artificial para ofrecer respuestas más rápidas y precisas. Según SEMrush, cerca de 170 usuarios buscan “Coolboox atención al cliente” cada mes, lo que demuestra la importancia de un servicio eficiente. <br /><br />Con Beex, puedes reemplazar bots de opciones predefinidas por chatbots inteligentes que agilizan consultas, resuelven problemas complejos y liberan a los agentes para que atiendan casos prioritarios.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Reduce el enrutamiento de bot a agente",
            description: "<p>Actualmente, la transferencia de consultas de un bot a un agente en Coolbox puede demorar hasta 2 horas, lo que afecta significativamente la experiencia del cliente. Con Beex, puedes implementar un enrutamiento inteligente que asigna las consultas al agente disponible más adecuado en tiempo real. <br/> <br/>Además, los filtros avanzados permiten gestionar la carga de trabajo, asegurando que las solicitudes se deriven al agente con menos tickets abiertos. Esto reduce los tiempos de espera y mejora la satisfacción del cliente.</p>",
            image: ImageAccordionLeftThree,
        },
    ],
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Latam. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    bannerVideo: {
        title: "¿Cómo automatizar las campañas de marketing de los e-commerce?",
        description: "<p>Descubre cómo estructurar tus campañas de marketing para e-commerce aprovechando herramientas de automatización que optimizan procesos y ahorran tiempo. <br /><br /> Aprende sobre las acciones clave para integrar tecnología y mejorar la gestión de tus estrategias en plataformas digitales. Explora los pasos prácticos para implementar soluciones eficientes.</p>",
        image: imageVideo
    },
}

export default data
